import cx from 'classnames';

import styles from './Home.module.scss';

const PercentageInputSet = ({
  label,
  value,
  onChange = () => {},
  isReadOnly = false,
}) => {
  return (
    <div className={styles['input-box']}>
      <label>{label}</label>
      <div className={cx(styles.input, styles.percentage)}>
        <input
          type="number"
          min={0}
          value={value}
          onChange={onChange}
          readOnly={isReadOnly}
        />
      </div>
    </div>
  );
};

export default PercentageInputSet;
