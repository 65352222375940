import { createSlice } from '@reduxjs/toolkit';
import { isMobileOnly } from 'react-device-detect';

export const initialState = {
  view: isMobileOnly ? 'mobile' : 'desktop',
};

const UISlice = createSlice({
  name: '@@sales-kit/ui',
  initialState,
  reducers: {
    updateUIPairs(state, action) {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
});

export const { updateUIPairs } = UISlice.actions;

export default UISlice.reducer;
