import { combineReducers } from 'redux';

import ui from './ui';

const appReducer = combineReducers({
  ui,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const resetStore = () => ({
  type: 'RESET',
});

export default rootReducer;
