import { useEffect, useState } from 'react';
// import Decimal from 'decimal.js';
import Switch from 'react-switch';
import {
  NumberParam,
  StringParam,
  useQueryParam,
  BooleanParam,
} from 'use-query-params';

import Printable from './Printable';
import PercentageInputSet from './PercentageInputSet';
import { curFormat } from './utils';

import styles from './Home.module.scss';

// fixed value vars
// const ccFee = 0.035;
// const ccFeeInPercentage = new Decimal(ccFee).times(100);
const DEFAULT_NOC = 100;
const DEFAULT_AOV = 8000;

const Home = () => {
  const [isLocked] = useQueryParam('locked', BooleanParam);
  const [merchantName] = useQueryParam('merchant-name', StringParam);

  // UI
  const [isInAdvancedMode, setIsInAdvancedMode] = useState(false);
  const [currentNumberOfCustomers, setCurrentNumberOfCustomers] = useState(0);
  const [aovToday, setAOVToday] = useState(0);

  // credit card fee
  const [ccFeeInPercentage, setCCFeeInPercentage] = useQueryParam(
    'cc-fee-in-percentage',
    NumberParam
  );
  // Smartpay fee
  const [smartpayFeeInPercentage, setSmartpayFeeInPercentage] = useQueryParam(
    'smartpay-fee-in-percentage',
    NumberParam
  );
  // Increase in customers driven by Smartpay platform
  const [customerIncreaseInPercentage, setCustomerIncreaseInPercentage] =
    useQueryParam('customer-increase-in-percentage', NumberParam);
  // Increase in conversion driven by Smartpay
  const [conversionIncreaseInPercentage, setConversionIncreaseInPercentage] =
    useQueryParam('conversion-increase-in-percentage', NumberParam);
  // Checkout share of Smartpay
  const [
    smartpayCheckoutShareInPercentage,
    setSmartpayCheckoutShareInPercentage,
  ] = useQueryParam('smartpay-checkout-share-in-percentage', NumberParam);
  // Increase in AOV for Smartpay consumers
  const [aovIncreaseInPercentage, setAOVIncreaseInPercentage] = useQueryParam(
    'aov-increase-in-percentage',
    NumberParam
  );

  useEffect(() => {
    if (
      !ccFeeInPercentage &&
      !smartpayFeeInPercentage &&
      !customerIncreaseInPercentage &&
      !conversionIncreaseInPercentage &&
      !smartpayCheckoutShareInPercentage &&
      !aovIncreaseInPercentage
    ) {
      setCCFeeInPercentage(3.5);
      setSmartpayFeeInPercentage(2);
      setCustomerIncreaseInPercentage(0);
      setConversionIncreaseInPercentage(5);
      setSmartpayCheckoutShareInPercentage(20);
      setAOVIncreaseInPercentage(20);
    }
  }, []); // eslint-disable-line

  // number of custoumers
  const numberOfCustomers = currentNumberOfCustomers || DEFAULT_NOC;
  const aov = aovToday || DEFAULT_AOV;

  /**
   * real numbers from user inputs
   */
  const ccFee = ccFeeInPercentage / 100;
  const smartpayFee = smartpayFeeInPercentage / 100;
  const customerIncrease = customerIncreaseInPercentage / 100;
  const conversionIncrease = conversionIncreaseInPercentage / 100;
  const smartpayCheckoutShare = smartpayCheckoutShareInPercentage / 100;
  const aovIncrease = aovIncreaseInPercentage / 100;

  /**
   * Increase costs on exisitng online revenue
   */
  const numberOfNewUsers =
    (customerIncrease + conversionIncrease) * numberOfCustomers;

  const numberOfSmartpayUsers =
    (numberOfCustomers + numberOfNewUsers) * smartpayCheckoutShare;
  const numberOfExistingSmartpayUsers =
    numberOfSmartpayUsers - numberOfNewUsers;

  const ccShiftedToSmartpay = numberOfExistingSmartpayUsers / numberOfCustomers;

  const currentOnlineRevenue = numberOfCustomers * aov;
  const totalIncreasedCost =
    currentOnlineRevenue * ccShiftedToSmartpay * smartpayFee;

  /**
   * Cost on incremental revenue
   */
  const incremental_gross_lost_revenue =
    numberOfNewUsers * aov * (1 + aovIncrease) +
    numberOfExistingSmartpayUsers * aov * aovIncrease;

  // Smartpay cost on Gross lost revenue
  const incremental_smartpay_cost_on_gross_lost_revenue = ccFee + smartpayFee;
  const incremental_total_smartpay_cost_on_gross_lost_revenue =
    incremental_gross_lost_revenue *
    incremental_smartpay_cost_on_gross_lost_revenue;
  const total_increase_in_cost =
    totalIncreasedCost + incremental_total_smartpay_cost_on_gross_lost_revenue;

  /**
   * Net impact on revenue
   */
  const impact_on_gross_lost_revenue = incremental_gross_lost_revenue;
  // const impact_on_total_increase_in_cost =
  //   totalIncreasedCost + incremental_smartpay_cost_on_gross_lost_revenue;
  const impact_on_net_lost_revenue =
    impact_on_gross_lost_revenue - total_increase_in_cost;

  const uplift = impact_on_net_lost_revenue / currentOnlineRevenue;

  // console.log({
  //   smartpayFee,
  //   total_increase_in_cost,
  //   totalIncreasedCost,
  //   numberOfNewUsers,
  //   numberOfSmartpayUsers,
  //   numberOfExistingSmartpayUsers,
  //   impact_on_gross_lost_revenue,
  //   incremental_gross_lost_revenue,
  //   incremental_total_smartpay_cost_on_gross_lost_revenue,
  //   incremental_smartpay_cost_on_gross_lost_revenue,
  //   impact_on_total_increase_in_cost,
  //   impact_on_net_lost_revenue,
  //   currentOnlineRevenue,
  //   uplift,
  // });

  return (
    <div className={styles.content}>
      <main className="for-screen">
        <form>
          {merchantName && merchantName.trim() && (
            <h1>{merchantName.trim()}様</h1>
          )}
          <h2>
            <span>Smartpay導入による増益シミュレーション</span>
            {!isLocked && (
              <div className={styles.switch}>
                アドバンスド
                <Switch
                  className={styles.trigger}
                  checked={isInAdvancedMode}
                  onChange={() => {
                    setIsInAdvancedMode(!isInAdvancedMode);
                  }}
                  offColor="#fd7a64"
                  onColor="#7280ff"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={21}
                  width={38}
                  handleDiameter={15}
                  activeBoxShadow=""
                />
              </div>
            )}
          </h2>

          <fieldset>
            <PercentageInputSet
              label="クレジットカード⼿数料"
              value={ccFeeInPercentage}
              isReadOnly={isLocked}
              onChange={(e) => setCCFeeInPercentage(+e.currentTarget.value)}
            />
            <PercentageInputSet
              label="Smartpay⼿数料"
              value={smartpayFeeInPercentage}
              isReadOnly={isLocked}
              onChange={(e) =>
                setSmartpayFeeInPercentage(+e.currentTarget.value)
              }
            />
          </fieldset>

          <h3>Smartpay情報</h3>

          <fieldset>
            <PercentageInputSet
              label="Smartpayによる顧客数の増加"
              value={customerIncreaseInPercentage}
              isReadOnly={isLocked}
              onChange={(e) =>
                setCustomerIncreaseInPercentage(+e.currentTarget.value)
              }
            />
            <PercentageInputSet
              label="Smartpayによるコンバージョンの増加"
              value={conversionIncreaseInPercentage}
              isReadOnly={isLocked}
              onChange={(e) =>
                setConversionIncreaseInPercentage(+e.currentTarget.value)
              }
            />
          </fieldset>
          <fieldset>
            <PercentageInputSet
              label="Smartpayのチェックアウトシェア"
              value={smartpayCheckoutShareInPercentage}
              isReadOnly={isLocked}
              onChange={(e) =>
                setSmartpayCheckoutShareInPercentage(+e.currentTarget.value)
              }
            />
            <PercentageInputSet
              label="Smartpay利⽤者のAOVの増加"
              value={aovIncreaseInPercentage}
              isReadOnly={isLocked}
              onChange={(e) =>
                setAOVIncreaseInPercentage(+e.currentTarget.value)
              }
            />
          </fieldset>
          {isInAdvancedMode && (
            <>
              <h3>貴社情報（任意）</h3>
              <fieldset>
                <div className={styles['input-box']}>
                  <label>AOV</label>
                  <div className={styles.input}>
                    <input
                      type="number"
                      min={0}
                      value={aovToday}
                      onChange={(e) => setAOVToday(+e.currentTarget.value)}
                    />
                  </div>
                </div>
                <div className={styles['input-box']}>
                  <label>アクティブ会員数</label>
                  <div className={styles.input}>
                    <input
                      type="number"
                      min={0}
                      value={currentNumberOfCustomers}
                      onChange={(e) =>
                        setCurrentNumberOfCustomers(+e.currentTarget.value)
                      }
                    />
                  </div>
                </div>
              </fieldset>
            </>
          )}
        </form>
        <div className={styles.result}>
          <div className={styles.uplift}>
            {isNaN(uplift) ? 0 : (uplift * 100).toFixed(2)}
            <span>%</span>{' '}
            {isInAdvancedMode &&
              !!currentNumberOfCustomers &&
              !!aovToday &&
              `(${curFormat(impact_on_net_lost_revenue)})`}
          </div>
          <div className={styles.label}>Smartpayによる純収益の増加</div>
        </div>
      </main>
      <Printable
        merchantName={merchantName}
        currentNumberOfCustomers={currentNumberOfCustomers}
        aovToday={aovToday}
        impactOnNetLostRevenue={impact_on_net_lost_revenue}
        uplift={uplift}
        ccFeeInPercentage={ccFeeInPercentage}
        smartpayFeeInPercentage={smartpayFeeInPercentage}
        customerIncreaseInPercentage={customerIncreaseInPercentage}
        conversionIncreaseInPercentage={conversionIncreaseInPercentage}
        smartpayCheckoutShareInPercentage={smartpayCheckoutShareInPercentage}
        aovIncreaseInPercentage={aovIncreaseInPercentage}
        showAdvancedNumbers={
          isInAdvancedMode && !!currentNumberOfCustomers && !!aovToday
        }
      />
    </div>
  );
};

export default Home;
