import { Link } from '@reach/router';

import Logo from '../assets/logo.svg';

import styles from './Header.module.scss';

const Header = () => {
  return (
    <div className={styles.header}>
      <header>
        <Link to="/" className={styles.logo}>
          <img src={Logo} height={32} alt="Smartpay | Sales Kit" />
        </Link>
      </header>
    </div>
  );
};

export default Header;
