import { Router, globalHistory } from '@reach/router';
import { QueryParamProvider } from 'use-query-params';

import Layout from './components/Layout';
import Home from './Home';

import styles from './App.module.scss';

function App() {
  return (
    <Layout>
      <Router primary={false} className={styles.content}>
        <QueryParamProvider {...{ path: '/' }} reachHistory={globalHistory}>
          <Home path="/" />
        </QueryParamProvider>
      </Router>
    </Layout>
  );
}

export default App;
