import cx from 'classnames';

import Logo from './assets/logo-with-space.svg';
import { curFormat } from './utils';

import styles from './Printable.module.scss';

const Printable = ({
  merchantName,
  currentNumberOfCustomers,
  aovToday,
  impactOnNetLostRevenue,
  uplift,
  ccFeeInPercentage,
  smartpayFeeInPercentage,
  customerIncreaseInPercentage,
  conversionIncreaseInPercentage,
  smartpayCheckoutShareInPercentage,
  aovIncreaseInPercentage,
  showAdvancedNumbers,
}) => {
  return (
    <main className={cx('for-print', styles.main)}>
      <img src={Logo} height={80} alt="Smartpay | Sales Kit" />
      <h1>Smartpay導⼊による増益シミュレーション</h1>
      {merchantName && (
        <p className={styles['merchant-name']}>{merchantName}様</p>
      )}
      {showAdvancedNumbers && (
        <article>
          <h2>貴社情報</h2>
          <ul>
            <li>
              <span className={styles.label}>アクティブ会員数</span>
              <span className={styles.value}>{currentNumberOfCustomers}</span>
            </li>
            <li>
              <span className={styles.label}>AOV</span>
              <span className={styles.value}>{curFormat(aovToday)}</span>
            </li>
          </ul>
        </article>
      )}
      <article>
        <h2>Smartpay情報</h2>
        <ul>
          <li>
            <span className={styles.label}>クレジットカード⼿数料</span>
            <span className={styles.value}>{ccFeeInPercentage} %</span>
          </li>
          <li>
            <span className={styles.label}>Smartpay⼿数料</span>
            <span className={styles.value}>{smartpayFeeInPercentage} %</span>
          </li>
          <li>
            <span className={styles.label}>Smartpayによる顧客数の増加</span>
            <span className={styles.value}>
              {customerIncreaseInPercentage} %
            </span>
          </li>
          <li>
            <span className={styles.label}>
              Smartpayによるコンバージョンの増加
            </span>
            <span className={styles.value}>
              {conversionIncreaseInPercentage} %
            </span>
          </li>
          <li>
            <span className={styles.label}>Smartpayのチェックアウトシェア</span>
            <span className={styles.value}>
              {smartpayCheckoutShareInPercentage} %
            </span>
          </li>
          <li>
            <span className={styles.label}>Smartpay利⽤者のAOVの増加</span>
            <span className={styles.value}>{aovIncreaseInPercentage} %</span>
          </li>
        </ul>
      </article>
      <article>
        <h2>シミュレーション結果</h2>
        <ul>
          {showAdvancedNumbers && (
            <li>
              <span className={styles.label}>Smartpayによる純収益増加額</span>
              <span className={cx(styles.value, styles.hightlight)}>
                {curFormat(impactOnNetLostRevenue)}
              </span>
            </li>
          )}
          <li>
            <span className={styles.label}>Smartpayによる純収益増加率</span>
            <span className={cx(styles.value, styles.hightlight)}>
              {isNaN(uplift) ? 0 : (uplift * 100).toFixed(2)} %
            </span>
          </li>
        </ul>
      </article>
    </main>
  );
};

export default Printable;
